<template>
  <div :style="{ backgroundImage: 'url(' + url + ')', backgroundSize:'contain', height:'200px'}" @click="fullScreen = true"></div>
  <v-dialog
      v-model="fullScreen"
      width="auto"
  >
    <div :style="{ backgroundImage: 'url(' + url + ')', backgroundSize:'contain', height:'90vh', width:'90vw', backgroundPosition: 'center'}" @click="fullScreen = false"></div>
  </v-dialog>
</template>
     
<script>

export default {
    name: 'ImageDisplay',
    props: ['teamId', 'fileName'],
    mounted() {
      if(this.fileName){
        this.url = `/api/teams/${this.teamId}/images/${this.fileName}`;
      }
    },
    data() {
        return {
          url:"",
          fullScreen: false,
        }
    },

}
</script>
